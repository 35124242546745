import React from "react";
import {
    Box,
    Icon,
    Button,
    Heading,
    Stack,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption
} from "@chakra-ui/react";
import {
    Paginator,
    Container,
    Previous,
    Next,
    PageGroup,
    usePaginator
} from "chakra-paginator";

import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import Spin from "../components/spin";
import NavBar from "../components/navbar";
import { useHasMounted } from "../components/hooks";
import HomeButton from "../components/home";

import { vitalizeConstants } from "../utils/constants";
import getFirebase from "../utils/firebase";
import UserMgr from "../utils/user_manager";
const UMObj = UserMgr.getInstance();


const HistoryOrder = (props) => {
    const order = props.order;
    const orderItems = props.items;
    // console.log(order)
    // console.log(orderItems)
    return (
        <Table variant="striped" size="sm">
            <TableCaption>
                <Text fontSize="sm">Ordered on {order.orderDate}</Text>
                <Text fontSize="sm">at {order.orderTime}</Text>
            </TableCaption>
            <Thead>
                <Tr>
                <Th>Product</Th>
                <Th>Category</Th>
                <Th>Qty</Th>
                </Tr>
            </Thead>
            <Tbody>
                {orderItems.map((order, i) =>
                    <Tr key={"historyitem_" + i}>
                    <Td>{order.item_name.split("/")[0]}</Td>
                    <Td>{order.item_category}</Td>
                    <Td>{order.quantity}</Td>
                    </Tr>
                )}
            </Tbody>
        </Table>
    )
}

const HistoryList = (props) => {
    const [filteredOrders, setFilteredOrders] = React.useState(null);
    var updated = [];

    // Paginator options
    const pagesQuantity = props.history.length;
    const { currentPage, setCurrentPage } = usePaginator({
        initialState: { 
            pageSize: 1,
            currentPage: 1,
        }
    });

    // Paginator constants
    const outerLimit = 2;
    const innerLimit = 2;

    // Paginator styles
    const baseStyles = {
        w: 7,
        fontSize: "sm"
    };
    const normalStyles = {
        ...baseStyles,
        _hover: {
            bg: "green.300"
        },
        bg: "gray.300"
    };
    const activeStyles = {
        ...baseStyles,
        _hover: {
            bg: "blue.300"
        },
        bg: "green.300"
    };
    const separatorStyles = {
        w: 7,
        bg: "green.200"
    };
    
    // If history is empty
    if (!props.history.length) {
        return (
            <Stack
                mx={{base: 2, sm: 4}} mt={6} w="100%"
                direction="row"
                spacing={4}
            >
                <Text fontSize="md">No orders placed</Text>
                <span role="img" aria-label={"Sad empty mailbox emoji"}>📭</span>
            </Stack>
        )
    }

    if (!filteredOrders) {
        props.history.forEach(order => {
            var filteredOrder = {};
            filteredOrder.orderDate = order.orderDate;
            filteredOrder.orderTime = order.orderTime;
            filteredOrder.items = order.items.filter(o => 
                o.item_name !== "Delivery Fee" && o.item_category !== "Delivery Fee" &&
                o.item_name !== "PRODUCT" && o.item_category !== "CATEGORY"
            )
            // console.log("FILT HIST: ", filteredOrders);
            updated.push(filteredOrder);
            
        })
        setFilteredOrders(updated);
    }

    const placeHistoryOrder = () => {
        // console.log("FILT HIST: ", filteredOrders[orderIdx]);
        // console.log("ORDER IDX: ", orderIdx);
        // const orderItems = filteredOrders[orderIdx].items;
        const orderItems = filteredOrders[currentPage-1].items
        console.log("ORDER ITEMS: ", orderItems);
        // Clear cart then add history items
        UMObj.CartClear();
        orderItems.forEach(item => {
            var prod = {};
            prod.fromHistory = true;
            prod.id = item.item_id;
            prod.quantity = item.quantity;
            const success = UMObj.CartAdd(prod);
            if (!success) console.log("CANNOT ADD HISTORY ITEMS");
        })
    }

    // If history has orders
    if(!filteredOrders) return null;
    return (
        <Paginator
            innerLimit={innerLimit}
            outerLimit={outerLimit}        
            pagesQuantity={pagesQuantity}
            activeStyles={activeStyles}
            normalStyles={normalStyles}
            separatorStyles={separatorStyles}        
            currentPage={currentPage}
            onPageChange={setCurrentPage}
        >
            <Container align="center" justify="space-between" w="full" p={4}>
                <Previous>
                    {<Icon><MdChevronLeft size={30}/></Icon>}
                </Previous>
                <PageGroup isInline align="center" />
                <Next>
                    {<Icon><MdChevronRight size={30}/></Icon>}
                </Next>
            </Container>
            <HistoryOrder order={filteredOrders[currentPage-1]} items={filteredOrders[currentPage-1].items} />
            <Button
                w="100%"
                px={{base: "10%", md: "30%"}}
                align="center"
                onClick={placeHistoryOrder}
                colorScheme="blue"
            >
                Add to Cart
            </Button>
        </Paginator>
    )
}

const History = () => {
    const [history, setHistory] = React.useState(null);

    const hasMounted = useHasMounted();
    if (hasMounted) {
        // Firebase dynamic import
        var fbImportPromises = [];
        fbImportPromises.push(import("firebase/app"));
        fbImportPromises.push(import("firebase/auth"));
        fbImportPromises.push(import("firebase/firestore"));
        fbImportPromises.push(import("firebase/analytics"));
        
        Promise.all(fbImportPromises)
        .then(([fbAppModule, fbAuthModule, fbFSModule, fbAnalyticsModule]) => {
            const fb = getFirebase(fbAppModule);
            const fbApp = fb.getApp();
            const auth = fbAuthModule.getAuth(fbApp);
            fbAuthModule.useDeviceLanguage(auth);
            fbAuthModule.onAuthStateChanged(auth, UMObj.AuthListener);
            fbAuthModule.onIdTokenChanged(auth, UMObj.AuthListener);
            const fs = fbFSModule.getFirestore(fbApp);
            const analytics = fbAnalyticsModule.getAnalytics(fbApp);
            UMObj.SetAnalytics(analytics, fbAnalyticsModule);

            const currUser = UMObj.GetCurrentUser();
            const docRef = fbFSModule.doc(fs, "users", currUser.uid);
            fbFSModule.getDoc(docRef)
            .then(snap => {
                if (snap.exists() && snap.data()) setHistory(snap.data().orders);
            })
        })
    }

    return (
        (!history || !hasMounted) ? <Spin /> :
        (
            <Box as='main' bg='white' key="historypage">
                <Box 
                    position="sticky"
                    top="0"
                    margin={0}
                    zIndex={vitalizeConstants.Z_NAVBAR}
                >
                    <NavBar />
                </Box>
                <HomeButton />
                <Box mt={5} px={{base: "2", md: "30%"}} py={4} >
                    <Heading>Order History</Heading>
                    <HistoryList history={history}/>
                </Box>
            </Box>
        )
    )
}

export default History;