import React from "react";
import { Formik, Form, Field } from "formik";
import {
    Box,
    Flex,
    Text,
    Input,
    Select,
    Container,
    Badge,
    Spacer,
    Button,
    IconButton,
    ButtonGroup,
    Heading,
    Editable,
    EditablePreview,
    EditableInput,
    FormControl,
    FormErrorMessage,
    useInterval,
    useEditableControls
} from "@chakra-ui/react";
import { MdCheckCircle, MdCheck, MdClose, MdEdit } from "react-icons/md";

import Spin from "../components/spin";
import NavBar from "../components/navbar";
import { useHasMounted } from "../components/hooks";
import HomeButton from "../components/home";

import { vitalizeConstants } from "../utils/constants";
import getFirebase from "../utils/firebase";
import UserMgr from "../utils/user_manager";
import { isBrowser } from "../utils/utility_funcs";
const UMObj = UserMgr.getInstance();


const ValidatePhone = (props) => {
    const fb = props.fb;
    const userPhone = props.phone;
    const currUser = UMObj.GetCurrentUser();

    const validateCode = (value) => {
        let reg = /[a-zA-Z]+/;
        let error;
        if (!value) {
            if(!userPhone || userPhone === "" || userPhone.substring(0, 3) !== "+971") error = "Please enter phone number starting with +971";
            else error = "Please enter verification code sent to " + userPhone;
        } 
        else if (reg.test(value)) {
            error = "Please enter a valid numerical verification code";
        }
        return error;
    }

    return (
        <Formik
            initialValues={{ code: "" }}
            onSubmit={(values, actions) => {
                setTimeout(async () => {
                    console.log("REQUEST: ", JSON.stringify({
                        phone: userPhone,
                        code: values.code,
                        sid: UMObj.GetVerifySID()
                    }))
                    try {
                        const response = await fetch(process.env.URL_SERVER_VERIFYPHONE, {
                            body: JSON.stringify({
                                phone: userPhone,
                                code: values.code,
                                sid: UMObj.GetVerifySID()
                            }),
                            headers: {
                                "Content-Type": "application/json"
                            },
                            method: "POST"
                        });
                        const responseJSON = await response.json();
                        console.log(responseJSON);
                        if (!responseJSON) console.error("VERIFYPHONE FAILED ON SERVER SIDE");
                        else if (responseJSON.verified) {
                            console.log("VERIFICATION SUCCESS: ", userPhone);
                            const userRef = fb.fsModule.doc(fb.fs, "users", currUser.uid);
                            fb.fsModule.updateDoc(userRef, { phoneVerified: true });                    
                        }
                        else console.log(`INCORRECT CODE: ${values.code} for: ${userPhone}`);
                    }       
                    catch(err) {
                        actions.setFieldError("code", err);
                    }
                    actions.setSubmitting(false);
                }, 5000)
            }}
        >
            {(props) => (
                <Form>
                    <Flex direction="row">
                        <Field name="code" validate={validateCode}>
                        {({ field, form }) => (
                            <FormControl isInvalid={form.errors.code && form.touched.code}>
                                <Input {...field} id="code" placeholder="Verification Code" />
                                <FormErrorMessage>{form.errors.code}</FormErrorMessage>
                            </FormControl>
                        )}
                        </Field>
                        <IconButton
                            ml={4}
                            icon={<MdCheckCircle size={25} />}
                            colorScheme="teal"
                            isLoading={props.isSubmitting}
                            type="submit"
                        />
                    </Flex>
                </Form>
            )}
        </Formik>
    )
}

const ProfileEntries = (props) => {
    const fb = props.fb;
    const [currUser, setCurrUser] = React.useState(UMObj.GetCurrentUser());
    const [userDB, setUserDB] = React.useState(null);
    const [emailVerifyDisabled, setEmailVerifyDisabled] = React.useState(false);
    const [phoneVerifyDisabled, setPhoneVerifyDisabled] = React.useState(false);

    useInterval(() => {
        const userRef = fb.fsModule.doc(fb.fs, "users", currUser.uid);
        fb.fsModule.getDoc(userRef)
        .then(snap => {
            if (snap.exists() && snap.data()) {
                const queriedUser = snap.data();
                setUserDB(queriedUser);
                setCurrUser(UMObj.GetCurrentUser());

                // If we can skip verification
                if (queriedUser.emailVerified) setEmailVerifyDisabled(true);
                if (queriedUser.phoneVerified) setPhoneVerifyDisabled(true);

                // If we detect mismatch
                if (queriedUser.email !== currUser.email) {
                    fb.fsModule.updateDoc(userRef, { email: currUser.email });
                }
                if (!queriedUser.emailVerified && currUser.emailVerified) {
                    fb.fsModule.updateDoc(userRef, { emailVerified: true });
                }

                // TODO: fix this hack, it is a security vulnerability
                if (isBrowser()) {
                    var url = window.location.href;
                    var urlParams = url.split('?');
                    if (urlParams.length > 1) {
                        const [stat, ver] = urlParams[1].split("=");
                        if (stat === "status" && ver === "verified" && !queriedUser.emailVerified) {
                            fb.fsModule.updateDoc(userRef, { emailVerified: true });
                        }
                    }
                }
            }
        })
    }, 500);

    const EditableControlsEmail = () => {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls()
    
        return (isEditing ? (
            <ButtonGroup justifyContent="right" size="sm">
                <IconButton icon={<MdCheck />} {...getSubmitButtonProps()} />
                <IconButton icon={<MdClose />} {...getCancelButtonProps()} />
            </ButtonGroup>
            ) : (
            <Flex justifyContent="right">
                <IconButton size="sm" icon={<MdEdit />} {...getEditButtonProps()} />
            </Flex>
        ))
    }

    const handleEmailSubmit = (value) => {
        if (!value || value === "" || value === userDB.email) return;
        console.log("NEW EMAIL: ", value);
        const userRef = fb.fsModule.doc(fb.fs, "users", currUser.uid);
        fb.fsModule.updateDoc(userRef, { email: value, emailVerified: false })
        .then(() => {
            fb.authModule.updateEmail(currUser, "value")
            .catch(err => console.log(err))
        })
    }

    const sendEmailVerify = () => {
        setEmailVerifyDisabled(true);
        fb.authModule.sendEmailVerification(currUser, {
            url: process.env.URL_MAIN + "customer/profile?status=verified"
        });
        setTimeout(() => setEmailVerifyDisabled(false), 120000);
    }

    const EditableControlsPhone = () => {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls()
    
        return (isEditing ? (
            <ButtonGroup justifyContent="right" size="sm">
                <IconButton icon={<MdCheck />} {...getSubmitButtonProps()} />
                <IconButton icon={<MdClose />} {...getCancelButtonProps()} />
            </ButtonGroup>
            ) : (
            <Flex justifyContent="right">
                <IconButton size="sm" icon={<MdEdit />} {...getEditButtonProps()} />
            </Flex>
        ))
    }

    const handlePhoneSubmit = (value) => {
        if (!value || value === "" || value === userDB.phone) return;
        console.log("NEW PHONE: ", value);
        const userRef = fb.fsModule.doc(fb.fs, "users", currUser.uid);
        fb.fsModule.updateDoc(userRef, { phone: value, phoneVerified: false });
    }

    const sendPhoneVerify = async () => {
        setPhoneVerifyDisabled(true);
        try {
            const response = await fetch(process.env.URL_SERVER_SENDSMS, {
                body: JSON.stringify({
                    user: userDB
                }),
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST"
            });
            const responseJSON = await response.json();
            console.log(responseJSON);
            if (!responseJSON || !responseJSON.sid) console.error("SENDSMS FAILED ON SERVER SIDE");
            else UMObj.SetVerifySID(responseJSON.sid);
            console.log("VERIFY SID: ", UMObj.GetVerifySID(responseJSON.sid));
        }
        catch(err) {
            console.error(err.name);
            console.error(err.message);
        }
        setTimeout(() => setPhoneVerifyDisabled(false), 120000);
    }

    const handleAdLine1Submit = (value) => {
        if (!value || value === "" || value === userDB.adline1) return;
        console.log("NEW ADLINE 1: ", value);
        const userRef = fb.fsModule.doc(fb.fs, "users", currUser.uid);
        fb.fsModule.updateDoc(userRef, { adline1: value });
    }

    const handleAdLine2Submit = (value) => {
        if (!value || value === "" || value === userDB.adline2) return;
        console.log("NEW ADLINE 2: ", value);
        const userRef = fb.fsModule.doc(fb.fs, "users", currUser.uid);
        fb.fsModule.updateDoc(userRef, { adline2: value });
    }

    const handleEmirateSubmit = (event) => {
        const value = event.target.value;
        if (!value || value === "" || value === "-" || value === userDB.emirate) return;
        console.log("NEW EMIRATE: ", value);
        const userRef = fb.fsModule.doc(fb.fs, "users", currUser.uid);
        fb.fsModule.updateDoc(userRef, { emirate: value });
    }

    if(!userDB || !currUser) return <Spin />;
    return (
        <Container
            p={4}
            centerContent
        >
            <Box
                rounded="lg"
                p={4}
                bgColor="gray.300"
            >
                <Flex
                    my={4}
                    direction="column"
                >
                    <Flex
                        my={3}
                        direction="row"
                    >
                        <Heading size="md">Email</Heading>
                        <Editable
                            mx={4}
                            defaultValue={userDB.email ? userDB.email : "customer@example.com"}
                            isPreviewFocusable={false}
                            onSubmit={handleEmailSubmit}
                        >
                            <EditablePreview />
                            <EditableInput />
                            <EditableControlsEmail />
                        </Editable>
                        <Spacer />
                        <Badge
                            direction="row"
                            w="fit-content"
                            h="fit-content"
                            colorScheme={userDB.emailVerified ? "green" : "red"}
                        >
                            {userDB.emailVerified ? "Verified" : "Not Verified"} {userDB.emailVerified ? <MdCheck size={30}/> : <MdClose size={30}/>}
                        </Badge>
                    </Flex>
                    <Button
                        mt={2}
                        isDisabled={emailVerifyDisabled}
                        colorScheme="telegram"
                        w="fit-content"
                        onClick={sendEmailVerify}    
                    >
                        {emailVerifyDisabled ? "Sent!" : "Send Email Verification"}
                    </Button>
                </Flex>

                <Flex
                    my={4}
                    direction="column"
                >
                    <Flex
                        my={3}
                        direction="row"
                    >
                        <Heading size="md">Phone</Heading>
                        <Editable
                            mx={4}
                            defaultValue={userDB.phone ? userDB.phone : "+971"}
                            isPreviewFocusable={false}
                            onSubmit={handlePhoneSubmit}
                        >
                            <EditablePreview />
                            <EditableInput />
                            <EditableControlsPhone />
                        </Editable>
                        <Spacer />
                        <Badge
                            direction="row"
                            w="fit-content"
                            h="fit-content"
                            colorScheme={userDB.phoneVerified ? "green" : "red"}
                        >
                            {userDB.phoneVerified ? "Verified" : "Not Verified"} {userDB.phoneVerified ? <MdCheck size={30}/> : <MdClose size={30}/>}
                        </Badge>
                    </Flex>
                    <Text
                        display={userDB.phoneVerified ? "none" : "inherit"}
                        fontSize="sm"
                        maxWidth="75%"
                    >
                        <u>Please enter a UAE number starting in +971</u>
                    </Text>
                    <Button
                        mt={2}
                        isDisabled={phoneVerifyDisabled}
                        colorScheme="telegram"
                        w="fit-content"
                        onClick={sendPhoneVerify}    
                    >
                        {phoneVerifyDisabled ? "Sent!" : "Send Phone Verification"}
                    </Button>
                    <Text
                        display={userDB.phoneVerified ? "none" : "inherit"}
                        mt={1}
                        fontSize="sm"
                        as="i"
                        color="slateblue"
                        maxWidth="75%"
                    >
                        By continuing you will receive a one-time verification code to your phone number by SMS. Message and data rates may apply.
                    </Text>
                    <Box
                        display={userDB.phoneVerified ? "none" : "inherit"}
                        mt={3}
                    >
                        <ValidatePhone phone={userDB.phone} fb={{fsModule: fb.fsModule, fs: fb.fs}}/>
                    </Box>
                </Flex>

                <Flex
                    my={4}
                    direction="column"
                >
                    <Flex
                        my={3}
                        direction="row"
                    >
                        <Heading size="md">Address</Heading>
                        <Flex
                            my={3}
                            ml={-12}
                            direction="column"
                        >
                            <Heading mt={6} size="sm">Line 1</Heading>
                            <Editable
                                defaultValue={userDB.adline1}
                                isPreviewFocusable={false}
                                onSubmit={handleAdLine1Submit}
                            >
                                <EditablePreview />
                                <EditableInput />
                                <EditableControlsPhone />
                            </Editable>
                            <Heading mt={4} size="sm">Line 2</Heading>
                            <Editable
                                defaultValue={userDB.adline2}
                                isPreviewFocusable={false}
                                onSubmit={handleAdLine2Submit}
                            >
                                <EditablePreview />
                                <EditableInput />
                                <EditableControlsPhone />
                            </Editable>
                            <Heading mt={4} size="sm">Emirate</Heading>
                            <Select
                                onChange={handleEmirateSubmit}
                                placeholder={(!userDB.emirate || userDB.emirate === "-" || userDB.emirate === "") ? "-" : userDB.emirate}
                                variant="filled"
                            >
                                {vitalizeConstants.SUPPORTED_EMIRATES.map((emirate, i) => {
                                        if (emirate !== userDB.emirate) return <option key={"emi"+i}>{emirate}</option>
                                        return null;
                                    }
                                )}
                            </Select>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        </Container>
    )
}

const Profile = () => {
    const [authModule, setAuthModule] = React.useState(null);
    const [auth, setAuth] = React.useState(null);
    const [fsModule, setFSModule] = React.useState(null);
    const [fs, setFS] = React.useState(null);

    const hasMounted = useHasMounted();
    if (hasMounted) {
        // Firebase dynamic import
        var fbImportPromises = [];
        fbImportPromises.push(import("firebase/app"));
        fbImportPromises.push(import("firebase/auth"));
        fbImportPromises.push(import("firebase/firestore"));
      
        Promise.all(fbImportPromises)
        .then(([fbAppModule, fbAuthModule, fbFSModule]) => {
            const fb = getFirebase(fbAppModule);
            const fbApp = fb.getApp();
            const auth = fbAuthModule.getAuth(fbApp);
            fbAuthModule.useDeviceLanguage(auth);
            fbAuthModule.onAuthStateChanged(auth, UMObj.AuthListener);
            fbAuthModule.onIdTokenChanged(auth, UMObj.AuthListener);
            const fs = fbFSModule.getFirestore(fbApp);

            setAuthModule(fbAuthModule);
            setAuth(auth);
            setFSModule(fbFSModule);
            setFS(fs);
        })
    }

    return (
        (!UMObj.GetCurrentUser() || !auth || !fs || !hasMounted) ? <Spin /> :
        (
            <Box as='main' bg='white' key="profilepage">
                <Box 
                    position="sticky"
                    top="0"
                    margin={0}
                    zIndex={vitalizeConstants.Z_NAVBAR}
                >
                    <NavBar />
                </Box>
                <HomeButton />
                <Box mt={5} px={{base: "2", md: "30%"}} py={4} >
                    <Heading alignSelf="center">Profile</Heading>
                </Box>
                <ProfileEntries fb={{authModule: authModule, auth: auth, fsModule: fsModule, fs: fs}}/>
            </Box>
        )
    )
}

export default Profile;