import React from "react";
import { Router } from "@reach/router";
import Profile from "../../components/profile";
import History from "../../components/history";
import LoginPage from "../login";
import PrivateRoute from "../../components/private_route";

const Customer = () => (
    <Router basepath="/customer">
        <PrivateRoute path="/" component={LoginPage} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/history" component={History} />
        {/* <LoginPage path="/login" /> */}
    </Router>
)

export default Customer;
