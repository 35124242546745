import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import { useHasMounted } from "../components/hooks";
import UserMgr from "../utils/user_manager";
const UMObj = UserMgr.getInstance();


const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const hasMounted = useHasMounted();

    // Check for authenticated access
    if (!UMObj.GetCurrentUser()) {
        // If we’re not logged in, redirect to the login page.
        navigate("/login");
        return null;
    }
    console.log("AUTHED LOCATION: ", location)

    return (
        <div key={location}>
        {!hasMounted ? null : <Component {...rest} />}
        </div>
    )
}

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
}

export default PrivateRoute;
